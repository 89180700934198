<template>
    <div :class="[theme.moneyinput.moneyinput, { [theme.moneyinput.isValidationError]: isError || isExternalError} ]"
           v-tooltip="(isError || isExternalError) && (validationErrorsText || externalErrorText) ? {
                    content: validationErrorsText ? validationErrorsText : externalErrorText ? externalErrorText : '',
                    placement: 'top-center',
                    arrowSelector: '.tooltip-arrow',
                    hideOnTargetClick: true,
                    trigger: 'manual',
                    show: (isError || isExternalError) && showTooltip,
                    offset: 15,
                    delay: { show: 0, hide: 0 },
                    container: false,
                } : ''"
    >

        <span :class="[theme.moneyinput.title, theme.moneyinput.headerText]">
            {{ title }}
        </span>

        <span v-if="available !== false" :class="theme.moneyinput.available">
            <span :class="theme.moneyinput.headerText">Available: <span :class="[theme.moneyinput.headerText, theme.moneyinput.violet]">{{ Number(available).floor(8).getSeparatedDigits() }}</span> {{ assetSymbol }}</span>
        </span>
        <div :class="theme.moneyinput.mainRow">
            <div>
                <input
                    type="text"
                    :value="disabled && isNullWhileDisabled ? '-' : inputValue"
                    :placeholder="placeholder"
                    :step="step"
                    :disabled="disabled || inputDisabled"
                    :class="{ [theme.moneyinput.disabled]: disabled || inputDisabled, [theme.moneyinput.textLeft]: isTextAlignedLeft }"
                    :readonly="readonly"
                    v-debounce="callVueDebounce"
                    v-currency="{
                        locale: 'en',
                        currency: null,
                        distractionFree: false,
                        precision: {
                            min: 0,
                            max: isMaxPrecisionGiven ? maxPrecision : 10
                        },
                        allowNegative: false
                    }"
                    @input="isChange($event)"
                    @focus="focus"
                    ref="input"
                />
            </div>
            <div v-if="currency" :class="s.currencyRowMargin">
                <div :class="s.currencyRow">
                    <span :class="{ [s.currencyMarginRight]: hasArrows }">
                        <div :class="[theme.moneyinput.text, s.row]">
                            <CryptoIcon
                                v-if="isCryptoIconNeeded"
                                :symbol="currency"
                                :class="s.currencyMarginRight"
                            />
                            {{ currency }}
                        </div>
                    </span>
                    <span v-if="hasArrows">
                        <div
                            @mousedown="startIncrement"
                            @mouseup="finishIncrement"
                            @mouseleave="finishIncrement"
                            @mouseout="finishIncrement"
                        >
                            <Icon
                                icon="arrow_down"
                                :class="s.rotated"
                                small
                            />
                        </div>
                        <div
                            @mousedown="startDecrement"
                            @mouseup="finishDecrement"
                            @mouseleave="finishDecrement"
                            @mouseout="finishDecrement"
                        >
                            <Icon
                                icon="arrow_down"
                                small
                            />
                        </div>
                    </span>
                </div>
            </div>
            <div v-if="assetsList.length > 0" :class="s.currencyRowMargin">
                <div :class="[s.currencyRow, s.relative]">
                    <span
                        @click="showAssetsDropdown = !showAssetsDropdown"
                        :class="[s.currencyMarginRight, s.pointer]"
                        :identifier="uniqueComponentId"
                        id="test"
                    >
                        <div :class="[theme.moneyinput.text, s.row]">
                            <CryptoIcon :symbol="selectedAsset" :class="s.currencyMarginRight" />
                            {{ selectedAsset }}
                        </div>
                    </span>
                    <span
                        @click="showAssetsDropdown = !showAssetsDropdown"
                        :class="s.pointer"
                        :identifier="uniqueComponentId"
                    >
                        <div>
                            <Icon
                                icon="arrow_down"
                            />
                        </div>
                    </span>
                    <div
                        v-if="showAssetsDropdown"
                        :class="theme.moneyinput.dropdown"
                        :identifier="uniqueComponentId"
                    >
                        <Search
                            v-if="withAssetSearch"
                            @on-search="searchAssetsValue = $event"
                            :class="s.mbS"
                        />
                        <div
                            :class="theme.moneyinput.dropdownRow"
                            v-for="asset in filteredAssetsList"
                            :key="asset"
                            @click="setActiveAsset(asset)"
                        >
                            <CryptoIcon
                                :symbol="asset"
                                :class="s.currencyMarginRight"
                            />
                            <span>
                                {{ asset }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="hasButtons" :class="theme.moneyinput.buttonsRow">
            <div
                v-for="(button, index) in buttons"
                :key="button.title"
                :class="[theme.moneyinput.button, { [theme.moneyinput.active]: focusedButton === index, [theme.moneyinput.disabled]: button.disabled || disabled || isButtonsDisabled || disabledButtonsIndexes.includes(index) }]"
                :style="`width: ${100 / buttons.length}%`"
                @click="runButtonCallback(button.callback, index, button)"
            >
                {{ button.title }}
            </div>
        </div>
    </div>
</template>

<script>
import { nanoid } from 'nanoid';

import theme from 'Theme';
import numberFormater from 'Mixins/numberFormater';
import Icon from 'UI/Icon.vue';
import { composedPath } from 'Lib/utils/eventPathChecker';
import Search from 'Control/Search.vue';

const AccelerationTypes = {
    INCREMENT: 1,
    DECREMENT: -1,
};
/*
* emits
* change-asset() => string
* */

export default {
    name: 'MoneyInput',
    mixins: [numberFormater],
    components: {
        Icon,
        Search,
    },
    props: {
        value: {
            type: [String, Number],
            default: '',
        },
        title: {
            type: String,
            default: '',
        },
        placeholder: {
            type: String,
            default: '',
        },
        step: {
            type: [String, Number],
            default: 'any',
        },
        required: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        currency: {
            type: String,
            default: '',
        },
        description: {
            type: String,
            default: null,
        },
        prefix: {
            type: [String, Number],
            default: null,
        },
        availableAssetSymbol: {
            type: String,
            default: null,
        },
        buttons: {
            type: Array,
            default: () => null,
        },
        defaultButtonNumber: {
            type: Number,
            default: -1,
        },
        validations: {
            type: Object,
            default: () => ({}),
        },
        restrictions: {
            type: Object,
            default: () => ({}),
        },
        minValue: {
            type: Number,
            default: null,
        },
        maxValue: {
            type: Number,
            default: null,
        },
        maxPrecision: {
            type: Number,
            default: null,
        },
        isInvalid: {
            type: Boolean,
            default: false,
        },
        hasArrows: {
            type: Boolean,
            default: false,
        },

        isExternalError: {
            type: Boolean,
            default: false,
        },
        externalErrorText: {
            type: String,
            default: '',
        },
        inputDisabled: {
            type: Boolean,
            default: false,
        },
        customValidationErrors: {
            type: Object,
            default: () => ({}),
        },
        isButtonsDisabled: {
            type: Boolean,
            default: false,
        },
        isAlwaysActive: {
            type: Boolean,
            default: false,
        },
        disabledButtonsIndexes: {
            type: Array,
            default: () => [],
        },
        showTooltip: {
            type: Boolean,
            default: true,
        },
        available: {
            type: undefined,
            default: false,
        },
        withAssetSearch: {
            type: Boolean,
            default: false,
        },
        assetsList: {
            type: Array,
            default: () => [],
        },
        selectedAsset: {
            type: String,
            default: '',
        },
        isCryptoIconNeeded: {
            type: Boolean,
            default: true,
        },
        isTextAlignedLeft: {
            type: Boolean,
            default: false,
        },
        isNullWhileDisabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isFocused: false,
            focusedButton: null,
            innerInputValue: '0',
            isIncrementingNow: false,
            isDecrementingNow: false,
            incrementInterval: null,
            decrementInterval: null,
            currentAccelerationType: AccelerationTypes.INCREMENT,
            currentAcceleration: 1,
            currentAccelerationID: 1,
            maxAcceleration: 5,
            accelerationStep: 0.05,
            accelerationDelay: 2000,
            accelerationButtonDelay: 20,
            theme,
            showAssetsDropdown: false,
            searchAssetsValue: '',
            uniqueComponentId: '',
        };
    },
    computed: {
        predictedCurrentAcceleration() {
            return this.currentAcceleration < this.maxAcceleration ? this.currentAcceleration : this.maxAcceleration;
        },
        filteredAssetsList() {
            if (this.searchAssetsValue === '') {
                return this.assetsList;
            }
            return this.assetsList.filter((a) => a.toLowerCase().indexOf(this.searchAssetsValue.toLowerCase()) !== -1);
        },
        assetSymbol() {
            if (this.availableAssetSymbol) {
                return this.availableAssetSymbol;
            }
            return this.assetsList.length > 0 ? this.selectedAsset : this.currency;
        },
        isStepGiven() {
            return typeof this.step === 'number';
        },
        isMaxPrecisionGiven() {
            return this.maxPrecision !== null;
        },
        hasButtons() {
            return this.buttons !== null;
        },
        inputValue() {
            const value = this.innerInputValue;
            const floatPart = String(value).split('.')[1];
            const intPart = String(value).split('.')[0];
            if (floatPart && floatPart.length > this.maxPrecision) {
                return `${intPart}.${floatPart.substring(0, this.maxPrecision)}`;
            }
            return this.innerInputValue;
        },
        $validations() {
            return this.validations ? this.validations : {};
        },
        isError() {
            return this.$validations.$error || this.isInvalid;
        },
        validationRequired() {
            return this.$validations.required;
        },
        validationMinLength() {
            return this.$validations.minLength;
        },
        validationMaxLength() {
            return this.$validations.maxLength;
        },
        validationMinValue() {
            return this.$validations.minValue;
        },
        validationMaxValue() {
            return this.$validations.maxValue;
        },
        validationMaxPrecision() {
            return this.$validations.maxPrecision;
        },
        validationDivision() {
            return this.$validations.division;
        },
        validationErrors() {
            const result = [];

            if (this.validationRequired === false) {
                result.push(`${this.title} field is required`);
            }

            if (this.validationMinValue === false) {
                try {
                    result.push(`${this.title} field must be above ${String(this.minValue) ? this.minValue.noExponents() : this.restrictions.minValue.noExponents()}`);
                } catch {
                    // validation error
                }
            }

            if (this.validationMaxValue === false) {
                try {
                    result.push(this.customValidationErrors.maxValue ? this.customValidationErrors.maxValue : `${this.title} field must be below ${String(this.maxValue) ? this.maxValue.noExponents() : this.restrictions.maxValue.noExponents()}`);
                } catch {
                    // validation error
                }
            }

            if (this.validationMaxPrecision === false) {
                result.push(`${this.title} field precision must be below or equal ${this.maxPrecision}`);
            }

            if (this.validationDivision === false) {
                result.push(`${this.title} field precision must be divisible by ${this.restrictions.stepSize ? this.restrictions.stepSize : this.step}`);
            }

            if (result.length === 0) {
                result.push(this.$validations);
            }

            return result;
        },
        validationErrorsText() {
            if (this.isError && this.validationErrors.length > 0) {
                return `<div>${this.validationErrors.join('<br>')}</div>`;
            }
            return null;
        },
    },
    methods: {
        cleanFocusButtons() {
            this.focusedButton = null;
            this.$forceUpdate();
        },
        isChange(event) {
            if (this.disabled || this.inputDisabled || this.readonly || !event.target) {
                return;
            }
            let givenValue = event.target ? event.target.value : String(event);
            if (givenValue === '') {
                this.$emit('input', 0);
                return;
            }

            if (event.data === ',') {
                givenValue = `${givenValue}.`;
            }
            const nonCommaValue = givenValue.replaceAll(',', '');

            if (nonCommaValue.indexOf('.') !== nonCommaValue.lastIndexOf('.')
                || !nonCommaValue.match(/^[0-9\.]+$/)
                || (!nonCommaValue.match(/^0\..*/) && !nonCommaValue.match(/^[1-9].*/) && nonCommaValue !== '0')) {
                givenValue = givenValue.slice(0, -1);
                event.target.value = givenValue;
                return;
            }

            this.focusedButton = null;
            const value = parseFloat(nonCommaValue);

            this.innerInputValue = nonCommaValue;

            if (value === 0) {
                this.$emit('input', 0);
                return;
            }
            if (String(nonCommaValue).indexOf('.') === -1 || (nonCommaValue[nonCommaValue.length - 1] !== '0' && nonCommaValue[nonCommaValue.length - 1] !== '.')) {
                if (!this.readonly) {
                    this.$emit('input', this.truncateNumber(value, this.maxPrecision));
                }
            }
        },
        focus(event) {
            this.isFocused = true;
            this.$emit('focus', event);
        },
        callVueDebounce(data) {
            if (this.vDebounce) {
                this.vDebounce(data);
                this.$emit('v-debounce-call');
            }
        },

        startIncrement() {
            this.isIncrementingNow = true;
            this.increment();

            setTimeout(() => {
                if (this.isIncrementingNow) {
                    if (this.incrementInterval !== null) {
                        clearInterval(this.incrementInterval);
                    }

                    this.incrementInterval = setInterval(() => {
                        this.increment();
                    }, this.accelerationButtonDelay);
                }
            }, this.accelerationButtonDelay * 10);
        },
        finishIncrement() {
            this.isIncrementingNow = false;

            if (this.incrementInterval !== null) {
                clearInterval(this.incrementInterval);
                this.incrementInterval = null;
            }
        },
        increment() {
            this.isChange(this.value + this.getAcceleratedStep(AccelerationTypes.INCREMENT));
            this.setFocus();
        },
        startDecrement() {
            this.isDecrementingNow = true;
            this.decrement();

            setTimeout(() => {
                if (this.isDecrementingNow) {
                    if (this.decrementInterval !== null) {
                        clearInterval(this.decrementInterval);
                    }

                    this.decrementInterval = setInterval(() => {
                        this.decrement();
                    }, this.accelerationButtonDelay);
                }
            }, this.accelerationButtonDelay * 10);
        },
        finishDecrement() {
            this.isDecrementingNow = false;

            if (this.decrementInterval !== null) {
                clearInterval(this.decrementInterval);
                this.decrementInterval = null;
            }
        },
        decrement() {
            this.isChange(Math.max(this.value - this.getAcceleratedStep(AccelerationTypes.DECREMENT), 0));
            this.setFocus();
        },

        getAcceleratedStep(type) {
            if (type !== this.currentAccelerationType) {
                this.currentAccelerationType = type;
                this.currentAcceleration = 1;

                this.currentAccelerationID = this.$store.getters.generateId();
            }

            const localeAccelerationID = this.currentAccelerationID;

            this.currentAcceleration += this.accelerationStep;

            setTimeout(() => {
                if (this.currentAccelerationID === localeAccelerationID) {
                    this.currentAcceleration -= this.accelerationStep;
                }
            }, this.accelerationDelay);

            return (this.isStepGiven ? this.step : 1) * (10 ** (Math.floor(this.predictedCurrentAcceleration) - 1));
        },

        setFocus() {
            if (!this.isFocused && this.$refs.input && typeof this.$refs.input.focus === 'function') {
                this.$refs.input.focus();
            }
        },

        runButtonCallback(callback, index, button) {
            if (button.disabled || this.disabled || this.isButtonsDisabled || this.disabledButtonsIndexes.includes(index)) {
                return;
            }
            if (callback && typeof callback === 'function') {
                callback();
            }
            if (this.defaultButtonNumber === -1) {
                this.focusedButton = index;
            }
        },
        setActiveAsset(asset) {
            this.showAssetsDropdown = false;
            this.searchAssetsValue = '';
            this.$emit('change-asset', asset);
        },
    },
    updated() {
        if (this.defaultButtonNumber !== -1) {
            this.focusedButton = this.defaultButtonNumber;
        }
    },
    mounted() {
        this.uniqueComponentId = nanoid(8);
        this.innerInputValue = this.noExponentialNumber(this.value);
        const { input } = this.$refs;
        if (input) {
            input.addEventListener('wheel', (e) => {
                if (!this.readonly && !this.disabled) {
                    if (e.deltaY > 0) {
                        this.decrement();
                    } else {
                        this.increment();
                    }

                    e.returnValue = false;
                    e.preventDefault();
                    return false;
                }
            });
        }
        document.addEventListener('click', (event) => {
            const path = composedPath(event.target);
            if (path) {
                const flag = path.some((el) => {
                    return !!(el.getAttribute && el.getAttribute('identifier') && el.getAttribute('identifier') === this.uniqueComponentId);
                });
                if (!flag) {
                    this.showAssetsDropdown = false;
                }
            }
        });
    },
    watch: {
        disabled(disabled) {
            if (disabled) {
                this.focusedButton = null;
            }
        },
        value(val) {
            if (!(val === 0 && parseFloat(this.innerInputValue) === 0) && !this.innerInputValue.endsWith('.')) {
                this.innerInputValue = Number.isNaN(this.noExponentialNumber(val)) ? this.noExponentialNumber(0) : this.noExponentialNumber(val);
            }
        },
    },
};
</script>

<style lang="postcss" module="s">
.rotated {
    transform: rotate(180deg);
}
.currencyRow {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.currencyRowMargin {
    margin: 0 var(--m-s);
}
.currencyMarginRight {
    margin-right: var(--m-s);
}
.row {
    display: flex;
    align-items: center;
}
.relative {
    position: relative;
}
.pointer {
    cursor: pointer;
}
.mbS {
    margin-bottom: var(--m-s);
}
</style>
